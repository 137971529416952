a {
  text-decoration: none;
  color: $color-text-default;
}
blockquote {
  margin: $line-spacing-default 0;
  padding-left: $line-spacing-large;
  // padding-right: $line-spacing-large;
  font-size: $font-size-small;
  @media #{$screen-tiny} {
    padding-right: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  cite {
    text-indent: 0;
    margin-top: $line-spacing-default;
  }
}
blockquote blockquote {
  text-align: center;
  border-left: 0;
  margin: 0;
  padding: 0;
  margin-left: $line-spacing-large;
  margin-bottom: $line-spacing-large;
  float: right;
  max-width: $width-content-max / 2;
  margin-right: -5vw;
  font-size: $font-size-small;
  p {
    font-weight: 300;
    font-style: italic;
    font-family: #{$font-family-display};
    font-size: 1.39rem;
    line-height: $line-height-tight;
  }
  cite {
    font-size: $font-size-small * 0.95;
  }
  @media #{$screen-medium} {
    margin-top: $line-spacing-large;
    max-width: 100%;
    float: none;
    padding-left: 0;
    border: 0;
    margin-left: -$line-spacing-large;
    position: relative;
    p {
      margin-right: -$line-spacing-large;
    }
    &:after {
      content: "";
      display: block;
      width: 1px;
      height: 100%;
      background: $color-background-default;
      position: absolute;
      left: -1px;
      top: 0;
    }
  }
}
#post blockquote:last-child blockquote {
  margin-top: $line-spacing-large;
  max-width: 100%;
  float: none;
  padding-left: 0;
  border: 0;
  margin-left: -$line-spacing-large;
  position: relative;
  p {
    margin-right: -$line-spacing-large;
  }
}
cite {
  display: block;
  font-style: normal;
  font-size: $font-size-small;
  font-family: #{$font-family-body-sc};
  letter-spacing: $letter-spacing-loose;
  text-transform: lowercase;
  i {
    font-family: #{$font-family-body};
    text-transform: none;
    letter-spacing: 0;
    font-style: italic;
  }
}
figure {
  margin: 0 0 $line-spacing-default 0;
  max-width: 100%;
}
figcaption,
.caption {
  display: block;
  margin: -($line-spacing-default - 0.5rem) 0 0 0;
  color: $color-text-light;
  font-size: $font-size-smaller;
  text-align: center;
  a {
    color: $color-text-light;
  }
}

article img {
  margin: $line-spacing-default 0;
  max-width: 100%;
  height: auto;
  margin: $line-spacing-default auto;
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  // text-align: center;
  margin: $line-spacing-large 0 $line-spacing-default 0;
  font-weight: normal;
  font-style: normal;
}
// .meta {
//   text-align: center;
// }
article {
  > h2:first-child,
  > h3:first-child,
  > h4:first-child,
  > h5:first-child,
  > h6:first-child,
  > pre:first-child,
  > blockquote:first-child,
  > ol:first-child,
  > ul:first-child {
    margin-top: 0;
  }
}
h1 {
  margin: 0;
  line-height: $line-height-tighter;
  font-size: $font-size-giant;
  font-family: #{$font-family-display};
  font-weight: bold;
  letter-spacing: -0.03em;
}
h1+h2 {
  font-size: $font-size-large;
  margin-top: 0.1em;
  margin-bottom: 0;
  font-weight: 400;
  border-radius: 5px;
}
.centered {
  h1,
  h1+h2 {
    text-align: center;
    @media #{$screen-small} {
      text-align: left;
    }
  }
}
h2 {
  font-size: $font-size-larger;
  font-style: italic;
  line-height: $line-height-tight;
}
h3 {
  font-family: #{$font-family-display};
  line-height: $line-height-tight;
  // text-transform: uppercase;
  // letter-spacing: 0.1rem;
  font-size: 1.5rem;
}
h4,
h5,
h6 {
  font-style: italic;
  font-size: $font-size-large;
}
hr {
  height: 1rem;
  line-height: 1rem;
  margin: $line-spacing-default 0;
  border: 0;
  text-align: center;
  &:after {
    display: inline-block;
    content: "· · ·";
    color: $color-text-lighter;
    word-spacing: 1rem;
  }
}
p {
  margin: 0 0 $line-spacing-default 0;
  hanging-punctuation: first;
}
.indented-paragraphs {
  p {
    margin: 0;
  }
  p+p {
    text-indent: $line-spacing-large;
  }
  hr {
    height: $line-spacing-large;
    margin: 0;
    &:after {
      display: none;
    }
  }
}
.img-only+p {
  text-indent: 0;
}
.is-poetry {
  // max-width: $width-content-max;
  p {
    margin: 0 0 $line-spacing-default 0;
    text-indent: 0;
  }
  h1,
  h2,
  h3,
  h4 {
    text-align: left;
  }
}
code {
  font-family: #{$font-family-mono};
  font-size: 0.85rem;
  letter-spacing: 0;
}
pre {
  letter-spacing: 0;
  font-family: #{$font-family-mono};
  line-height: $line-height-tight;
  background-color: $color-background-light;
  padding: $line-spacing-default $line-spacing-large;
  margin: $line-spacing-default 0;
  overflow-x: scroll;
  code {
    font-size: $font-size-smaller;
  }
}
strong {
  font-style: normal;
  font-weight: normal;
  font-family: #{$font-family-body-sc};
  // font-feature-settings: "smcp";
}
strong {
  // text-transform: lowercase;
}

em {
  font-style: italic;
}
sup,
sub {
  font-size: $font-size-tiny;
  padding-left: 1px;
}
table {
  margin: $line-spacing-default 0;
}
ul,
ol {
  margin: $line-spacing-default 0;
  padding: 0;
  li {
    margin-left: $line-spacing-large;
    // margin-right: $line-spacing-large;
    @media #{$screen-tiny} {
      margin-right: 0;
    }
  }
  ul,
  ol {
    margin: 0;
  }
}
/* Footnotes */
article hr + ol:last-child, article hr + ul:last-child {
  font-size: $font-size-smaller;
  word-wrap: break-word;
  margin-top: 0;
  margin-bottom: 0;
}
article hr + ul:last-child {
  list-style-type: none;
}
