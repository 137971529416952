// $color-link: rgb(195, 180, 140);
$color-brand: #FFE080;
$color-brand-secondary: #CC4A4E;

// $color-brand: rgb(52, 97, 175);
$color-link: lighten(desaturate($color-brand, 20%), 20%);

$color-selection: #FBF3AE;

$color-background-title: #DD6953;

$color-line-light: rgba(0,0,0,0.2);
$color-line-faint: rgba(0,0,0,0.08);
$color-text-default: rgba(0,0,0,0.8);
$color-text-light: rgba(0,0,0,0.4);
$color-text-lighter: rgba(0,0,0,0.5);
$color-text-overlay: rgba(255,255,255,0.8);
$color-background-default: rgb(255,255,255);
$color-background-secondary: #B04245;
$color-background-light: rgb(250,248,245);
$color-background-dark: rgb(50,52,56);
