@font-face {
    font-family: 'jost';
    src: url(/fonts/jost-700-bold-webfont.woff);
    font-style: normal;
    font-weight: 700;
}
@font-face {
    font-family: 'jost';
    src: url(/fonts/jost-500-medium-webfont.woff);
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: 'charter';
    src: url(/fonts/charter_regular-webfont.woff);
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'charter';
    src: url(/fonts/charter_italic-webfont.woff);
    font-style: italic;
    font-weight: 400;
}
@font-face {
    font-family: 'sourceserifpro-sc';
    src: url(/fonts/sourceserifpro-regular-sc-webfont.woff);
    font-style: normal;
    font-weight: 400;
}