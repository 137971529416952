$width-content-max: 600px;
$width-nav-max: 900px;

$font-size-ui: 14px;
$font-size-default: 20px;
$font-size-mobile-default: 19px;
$font-size-small: 0.92rem;
$font-size-smaller: 0.78rem;
$font-size-tiny: 0.68rem;
$font-size-large: 1.1rem;
$font-size-larger: 1.4rem;
$font-size-giant: 2.4rem;

$font-family-display: "jost, Gill Sans, Gill Sans Nova, Gill Sans MT, sans-serif";
$font-family-body: "charter, Georgia, serif";
$font-family-body-sc: "sourceserifpro-sc, charter, Georgia, serif";
$font-family-system: "'-apple-system', '.SFNSText-Regular', 'San Francisco', Roboto, 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif";
$font-family-mono: "Menlo, monospace";

$line-height-looser: 2;
$line-height-loose: 1.8;
$line-height-default: 1.5;
$line-height-tight: 1.35;
$line-height-tighter: 1.2;
$line-height-hairline: 1.15;

$line-spacing-small: 0.4rem;
$line-spacing-default: 1.45rem;
$line-spacing-large: 2.6rem;
$line-spacing-larger: 5rem;

$screen-micro: "screen and (max-width: 320px)";
$screen-tiny: "screen and (max-width: 400px)";
$screen-small: "screen and (max-width: 560px)";
$screen-medium: "screen and (max-width: 790px)";

$letter-spacing-tighter: -0.03em;
$letter-spacing-tight: -0.018em;
$letter-spacing-loose: 0.025em;
$letter-spacing-looser: 0.15em;
