@import "fonts";
@import "animations";
@import "colors";
@import "typography";
@import "formatting";

html {
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  font-size: $font-size-default;
  font-family: #{$font-family-body};
  line-height: $line-height-default;
  color: $color-text-default;
  background-color: $color-background-default;
  background-attachment: fixed;

  @media screen and (max-width: 420px) {
    font-size: $font-size-default - 2px;
    h1 { font-size: 1.85rem; }
    h2 { font-size: 1.25rem; }
    h3 { font-size: 1rem; }
  }
  @media screen and (max-width: 380px) {
    font-size: $font-size-default - 3px;
    h1 { font-size: 1.75rem; }
    h2 { font-size: 1.2rem; }
    h3 { font-size: 1rem; }
  }
  @media screen and (max-width: 320px) {
    font-size: $font-size-default - 4px;
    h1 { font-size: 1.55rem; }
    h2 { font-size: 1.15rem; }
    h3 { font-size: 1rem; }
  }
}


body {
  margin: 0;
  padding: 0;
  padding-top: 6rem;
  font-size: 100%;
  transition-property: transform;
  transition-duration: $duration-default;
}

nav {
  padding: 0 $line-spacing-default;
  box-sizing: border-box;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  width: 100%;
  height: 3.8rem;
  line-height: 4rem;
  background-color: $color-background-default;
  #nav-inner {
    max-width: $width-nav-max;
    margin: 0 auto;
  }
  #site-title {
    font-family: #{$font-family-display};
    font-style: normal;
    font-weight: 500;
    font-size: $font-size-large;
    letter-spacing: -0.02em;
    i {
      font-style: normal;
    }
  }
  #site-links {
    float: right;
    font-family: #{$font-family-display};
    font-weight: 500;
    font-size: $font-size-smaller;
    a {
      margin-left: 1rem;
    }
  }
}


article a,
#latest a,
#subscription a,
.underlined {
  text-decoration: none;
  color: $color-text-default;
  background-position: 0 0;
  background-image: linear-gradient(to top, $color-brand, $color-brand 0.15em, rgba(0, 0, 0, 0) 0.15em, rgba(0, 0, 0, 0));
  &:hover {
    background-color: $color-brand;
  }
}

article {
  padding: 0 $line-spacing-default;
  margin: 0 auto;
  max-width: $width-content-max;
}

#post {
  position: relative;
  margin-top: $line-spacing-large;
}


header {
  text-align: center;
  max-width: $width-nav-max;
  margin: 0 auto;
  padding: 0 $line-spacing-default;
  position: relative;
}






@media print {
  #adpacks {
    display: none;
  }
  #site-title {
    display: none;
  }
  #date {
    color: black !important;
  }
  html {
    color: rgb(0,0,0);
    line-height: $line-height-tight;
    font-size: $font-size-small;
  }
  h3,h4 {
    font-size: $font-size-small;
  }
  body {
    margin: 0 auto;
    max-width: 30em;
  }
  #post {
    opacity: 1;
  }
  #newsletter,
  footer,
  nav,
  aside,
  #meta,
  #share {
    display: none;
  }
}



footer {
  padding: 1rem;
  max-width: $width-content-max;
  margin: 0 auto;
  padding-top: 6rem;
  text-align: center;
  background: $color-background-default;
  // background: linear-gradient(to top, rgba(255,255,255,0.98), rgba(255,255,255,0.96));
  // box-shadow: 0 0 25px rgba(0,0,0,0.05), 0 -1px 0 rgba(0,0,0,0.05);
  font-size: $font-size-ui;
  color: $color-text-light;
}




#date {
  text-align: center;
  max-width: $width-content-max;
  margin: 0 auto;
  padding: 0 $line-spacing-default;
  font-size: $font-size-small;
  font-style: italic;
}


#archive {
  h3:first-of-type {
    margin-top: 0;
  }
}
.guest_author_box {
  padding: 1.6rem 2.6rem;
  background: rgb(250,248,245);
  margin-bottom: 1.6rem;
  font-size: 0.78rem;
}
.guest_author_box a {
  text-shadow: none !important;
}
.guest_author_name {
  font-size: 1rem;
  margin-bottom: 1rem;
}
#carbonads {
  min-height: 100px;
  position: relative;
  max-width: 340px;
  margin: 0 auto;
  padding: 0 $line-spacing-default 3rem $line-spacing-default;
  line-height: 1.35 !important;
}
.carbon-wrap {
}
.carbon-img {
  position: absolute;
  top: 0;
  left: $line-spacing-default;
}
.carbon-text {
  display: block;
  margin: 0;
  padding-left: 150px;
  font-size: $font-size-smaller;
  line-height: $line-height-tight;
}
.carbon-poweredby {
  display: block;
  margin: 0;
  padding-left: 150px;
  padding-top: 0.4rem;
  font-size: $font-size-tiny;
  color: rgba(0,0,0,0.3);
  line-height: $line-height-tight;
}

.bsa_it_ad {
  position: relative;
  border: 0 !important;
  background: transparent !important;
  max-width: 340px;
  padding: 0 !important;
  margin: 0 auto;
}
.bsa_it_d {
  font-size: 0.78rem !important;
  font-family: equity, Times, Times New Roman, serif !important;
  color: rgba(0,0,0,0.9) !important;
}

.resources {
  list-style-type: none;
  margin-left: 0;
  li {
    margin: 0 0 $line-spacing-large 0;
    clear: both;
  }
  img {
    display: inline-block;
    height: auto;
    margin: 0;
  }
  dt {
    font-weight: 700;
    font-size: $font-size-larger;
    font-family: #{$font-family-display};
    line-height: $line-height-tighter;
    letter-spacing: -0.01em;
    text-align: center;
    a:hover {
      background-color: transparent;
    }
  }
  dt:first-child {
    padding-bottom: 1rem;
    a {
      position: relative;
      img {
        box-shadow: 0 12px 25px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.05);
        border-radius: 2px;
      }
    }
  }
  dt:nth-of-type(3) {
    font-size: 1rem;
    font-family: #{$font-family-body};
    font-weight: normal;
    font-style: italic;
    padding-top: 0.5rem;
  }
  dt a {
    background-image: none;
  }
  dd {
    margin: 0.8rem 0 0 0;
  }
}
.note {
  font-style: italic;
}

$frameGradientSize: 6px;
$frameGradientStart: $color-brand;
$frameGradientMid: #E0868A;
$frameGradientEnd: #766396;

#frameGradient1,
#frameGradient2,
#frameGradient3,
#frameGradient4 {
  position: fixed;
  z-index: 100;
}
#frameGradient1 {
  top: 0;
  left: 0;
  width: 100%;
  height: $frameGradientSize;
  background-size: 400% 100%;
  background-image: linear-gradient(90deg, $frameGradientStart, $frameGradientMid, $frameGradientEnd, $frameGradientMid, $frameGradientStart);
}
#frameGradient2 {
  top: 0;
  right: 0;
  width: $frameGradientSize;
  height: 100%;
  background-size: 100% 400%;
  background-image: linear-gradient(180deg, $frameGradientMid, $frameGradientEnd, $frameGradientMid, $frameGradientStart, $frameGradientMid);
}
#frameGradient3 {
  bottom: 0;
  left: 0;
  width: 100%;
  height: $frameGradientSize;
  background-size: 400% 100%;
  background-position: 100% 0;
  background-image: linear-gradient(270deg, $frameGradientEnd, $frameGradientMid, $frameGradientStart, $frameGradientMid, $frameGradientEnd);
}
#frameGradient4 {
  top: 0;
  left: 0;
  width: $frameGradientSize;
  height: 100%;
  background-size: 100% 400%;
  background-position: 0 100%;
  background-image: linear-gradient(0deg, $frameGradientMid, $frameGradientStart, $frameGradientMid, $frameGradientEnd, $frameGradientMid);
}